/**
* Generated automatically at built-time (2025-01-02T07:21:02.990Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "induplus",templateKey: "sites/104-952e069a-e3ea-47ee-a8a6-a87856187d11"};