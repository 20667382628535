/**
* Generated automatically at built-time (2025-01-02T07:21:03.097Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik-cuisine-d-exterieur",templateKey: "sites/104-4b197296-ace7-47ae-9d5a-87639bb605a1"};